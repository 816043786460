import React from 'react'
import {EVENTS,sendPosthogData} from '@helpers/posthog'
import {useTranslation} from 'next-i18next'

import Sent from './Sent'

import styles from './styles.module.css'

export default function Bottom(props) {
  const {email, close} = props
  const {t} = useTranslation('website', {keyPrefix: 'layout.loginWithCode.modal.modalInner.bottom'})
  return (
    <div className={styles.container}>
      <Sent email={email} />
      <a
        className={styles.cancel}
        onClick={() => {
          sendPosthogData(EVENTS.clicked.omitLogin)
          close()
        }}>
        {t('labelOmit')}
      </a>
    </div>
  )
}

import React, {useEffect,useState} from 'react'
import hexToRgba from '@helpers/misc/hexToRgba'

import styles from './styles.module.css'

export default function LoadingText({
  lineHeight = 20,
  lines = 1,
  widthPercentage = 100,
  useAnimationDelay = true,
  color = '#e0e0e0',
  highlightColor = '#f5f5f5'
}) {
  const [animationDelay, setAnimationDelay] = useState(0)
  useEffect(() => {
    if (useAnimationDelay) setAnimationDelay(Math.random() * 0.5)
  }, [useAnimationDelay])

  const arr = Array.from(Array(lines).keys())
  const backgroundImage = `linear-gradient(90deg, ${hexToRgba(highlightColor, 0)} 0, ${hexToRgba(
    highlightColor,
    0.2
  )} 20%, ${hexToRgba(highlightColor, 0.5)} 60%, ${hexToRgba(highlightColor, 0)})`

  return (
    <div>
      {arr.map(key => (
        <div key={key}>
          <div
            className={styles.line}
            style={{height: lineHeight, width: `${widthPercentage}%`, backgroundColor: color}}>
            <div
              className={styles.after}
              style={{
                animationDelay: `${String(animationDelay).substring(0, 4)}s`,
                backgroundImage
              }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

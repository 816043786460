import React, {ComponentType} from 'react'
import {websiteLayout} from '@data/fragments/website/__generated__/websiteLayout'
import useWebsiteLayout from '@hooks/useWebsiteLayout'
import dynamic from 'next/dynamic'

export interface LayoutsProps {
  website: websiteLayout
  children: React.ReactNode
}

const layouts: {[key: string]: ComponentType<{children: React.ReactNode}>} = {
  default: dynamic(() => import('./Default')),
  taco: dynamic(() => import('./Taco')),
  tiramisu: dynamic(() => import('./Tiramisu'))
}

export default function Layouts(props: LayoutsProps) {
  const {layout} = useWebsiteLayout(props.website)

  const Component = layouts[layout]
  return <Component>{props.children}</Component>
}

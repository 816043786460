import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import InfoBanner from '@components/InfoBanner'
import LoadingText from '@components/LoadingText'
import {websiteInitialData_cached_website} from '@data/queries/website/__generated__/websiteInitialData_cached'
import isIFrame from '@helpers/misc/isIFrame'
import useIsSpecialLayout from '@hooks/useIsSpecialLayout'
import lazyHydrate from 'next-lazy-hydrate'

import isMobile from './DownloadApp/isMobile'
import Coupon from './Coupon'
import DownloadApp from './DownloadApp'
import ImportantMessage from './ImportantMessage'
import Layouts from './Layouts'
import LoginWithCode from './LoginWithCode'
import SetPreferences from './SetPreferences'

import styles from './styles.module.css'

const Footer = lazyHydrate(() => import('./Footer'), {on: ['hover'], compatibleMode: true})

export interface WebsiteProps {
  children: React.ReactNode
  website?: websiteInitialData_cached_website
}
export default function Website(props: WebsiteProps) {
  const layoutTheme = useIsSpecialLayout()
  return (
    <div>
      <ClientSuspense fallback={<LoadingText />}>
        {isMobile() ? <DownloadApp /> : null}
      </ClientSuspense>
      <InfoBanner />
      <ImportantMessage />
      <ClientSuspense fallback={<LoadingText />}>
        <Coupon />
      </ClientSuspense>
      <ClientSuspense fallback={<LoadingText />}>
        <SetPreferences />
      </ClientSuspense>
      <Layouts website={props.website}>
        <div>
          <LoginWithCode>
            <div className={styles.child}>{props.children}</div>
          </LoginWithCode>
          {isIFrame || layoutTheme === 'chat' ? null : <Footer />}
        </div>
      </Layouts>
    </div>
  )
}

import React, {lazy} from 'react'
import {useRouter} from 'next/router'

export default function CouponWrapper() {
  const router = useRouter()
  const couponCode = router.query.coupon ?? router.query.cupon

  if (!couponCode || couponCode === '') return null

  const Coupon = lazy(() => import('./Coupon'))

  return <Coupon couponCode={Array.isArray(couponCode) ? couponCode[0] : couponCode} />
}

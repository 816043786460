import React from 'react'
import Container from '@packages/justo-parts/lib/components/Container'
import useInitialData from '@page-components/Layout/useInitialData'

import styles from './styles.module.css'

export default function InfoBanner(props) {
  const {website} = useInitialData()
  const {importantMessageTableText} = website?.navbarConfiguration

  if (!importantMessageTableText) return null

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.description}>{importantMessageTableText}</div>
        </div>
      </Container>
    </div>
  )
}

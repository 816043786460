import {useEffect} from 'react'
import appOrderPreferences from '@data/fragments/preferences/appOrderPreferences.main'
import getQueryObject from '@helpers/misc/getQueryObject'
import useSessionStorageState from '@hooks/useSessionStorageState'
import useWebsiteId from '@hooks/useWebsiteId'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'
import isEmpty from 'lodash/isEmpty'
import {useRouter} from 'next/router'

const updatePreferences = async ({updates, websiteId, mutate}) => {
  await mutate({
    mutation: gql`
      mutation setUserPreferencesAtLoad(
        $websiteId: ID
        $storeId: ID
        $deliveryType: ID
        $tableName: String
        $placeId: ID
      ) {
        preferences: setUserPreferences(
          websiteId: $websiteId
          deliveryType: $deliveryType
          storeId: $storeId
          tableName: $tableName
          placeId: $placeId
        ) {
          _id
          deliveryType
          storeId
          tableName
          placeId
          ...appOrderPreferences
        }
      }
      ${appOrderPreferences}
    `,
    variables: {websiteId, ...updates}
  })
}

export default function SetPreferences() {
  const router = useRouter()
  const {deliveryType, storeId, tableName, placeId} = router.query
  const onTableParam = router.query.onTable
  const [onTable, setOnTable] = useSessionStorageState('onTable')
  const [, setOrderGroupId] = useSessionStorageState('orderGroupId')

  const websiteId = useWebsiteId()
  const mutate = useMutate()

  useEffect(() => {
    const updates: any = {}
    const query = getQueryObject(router)

    if (onTable) {
      // 3 hours maxTimeOnTable
      // Miliseconds * Seconds * Minutes * Hours
      const maxTimeOnTable = 1000 * 60 * 60 * 3
      const timeDifference = new Date().valueOf() - new Date(onTable).valueOf()

      if (timeDifference > maxTimeOnTable) {
        setOnTable(null)
        updates.deliveryType = 'delivery'
        updates.storeId = null
      }
    } else {
      if (deliveryType) {
        updates.deliveryType = deliveryType
        router.replace({pathname: window.location.pathname, query: {...query, deliveryType: ''}})
      }

      if (placeId) {
        updates.placeId = placeId
        router.replace({pathname: window.location.pathname, query: {...query, placeId: ''}})
      }

      if (storeId) {
        updates.storeId = storeId
        router.replace({pathname: window.location.pathname, query: {...query, storeId: ''}})
      }

      if (tableName) {
        updates.tableName = tableName
        router.replace({pathname: window.location.pathname, query: {...query, tableName: ''}})
      }

      if (onTableParam) {
        updates.onTable = true
        setOnTable(new Date())
        setOrderGroupId(null)
        router.replace({pathname: window.location.pathname, query: {...query, onTable: ''}})
      }
    }

    if (!isEmpty(updates)) {
      updatePreferences({updates, websiteId, mutate})
    }
  }, [router.query])

  return null
}
